import React from "react";
import "../css/LeftNav.css";
import HomeIntro from "./HomeIntro.js";
import Project from "./Project.js";
import Life from "./Life.js";
import Resume from "./Resume.js";
import Error from "./Error.js";
import { Layout, Menu } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  AppstoreOutlined,
  CloudOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Content, Footer, Sider } = Layout;

class LeftNav extends React.Component {
  render() {
    return (
      <div className="LeftNav">
        <Layout>
          <Router>
            <Sider
              style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
              }}
            >
              <div className="logo" />
              <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
                <Menu.Item key="1" icon={<UserOutlined />}>
                  <Link to="/home">Home</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<AppstoreOutlined />}>
                  <Link to="/project">Project</Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<TeamOutlined />}>
                  <Link to="/life">Life</Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<CloudOutlined />}>
                  <Link to="/resume">Resume</Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout className="site-layout" style={{ marginLeft: 200 }}>
              <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                <div
                  className="site-layout-background"
                  style={{ padding: 24, textAlign: "left" }}
                >
                  <Switch>
                    <Route exact path="/project" component={Project} />
                    <Route exact path="/life" component={Life} />
                    <Route exact path="/resume" component={Resume} />
                    <Route exact path={["/", "/home"]} component={HomeIntro} />
                    <Route component={Error} />
                  </Switch>
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>
                Zhaolin Zhang's Personal Website ©2020 Created by Zhaolin Zhang
              </Footer>
            </Layout>
          </Router>
        </Layout>
      </div>
    );
  }
}

export default LeftNav;
