import React from "react";
import { Typography, Row, Col } from "antd";
import Iframe from "react-iframe";

const { Paragraph } = Typography;

const typeToggle = function(url, type) {
    switch(type){
        case "IMG":
            return (<img src={url} alt="" width="100%" height="100%" />);
        case "VIDEO":
            return (<Iframe url={url} width="100%" height="450px" display="initial" position="relative"></Iframe>);
        default:
            return (<div></div>);
    }
}

export default function Segment(props) {
    const description = props.lifeShare.Description;
    const url = props.lifeShare.url;
    const type = props.lifeShare.type;
    return (
        <Row align="middle">
          <Col span={5}>
            <Typography>
              <Paragraph>{description}</Paragraph>
            </Typography>
          </Col>
          <Col span={1}></Col>
          <Col span={18}>
            <Typography>
              <Paragraph>
                {typeToggle(url, type)}
              </Paragraph>
            </Typography>
          </Col>
        </Row>
    );
}