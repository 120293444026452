import React from "react";
import Segment from "./Segment.js";
import data from "../res/data.json";
import { Typography, Row, Col } from "antd";

const { Title } = Typography;

class Life extends React.Component {
  render() {
    const lifeShares = data.LifeShares;
    const lifeItems = lifeShares.map((lifeShare, i) => (<Segment key={i} lifeShare={lifeShare} />));
    return (
      <div className="Life">  
        <Row>
          <Col span={24}>
            <Typography>
              <Title>Life</Title>
            </Typography>
          </Col>
        </Row>
        {lifeItems}
      </div>
    );
  }
}

export default Life;
