import React from "react";
import { Alert } from "antd";

class Error extends React.Component {
  render() {
    return (
      <div className="Error">
        <Alert
          message="404 - PAGE NOT FOUND"
          description="The page you are looking for not availble"
          type="error"
          showIcon
        />
      </div>
    );
  }
}

export default Error;