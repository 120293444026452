import React from "react";
import data from "../res/data.json";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import { Alert } from "antd";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

const proxyurl = "https://cors-anywhere.herokuapp.com/";

class Resume extends React.Component {
  render() {
    const resumeUrl = proxyurl + data.Resume.url;
    return (
      <div className="Resume">
        <Alert
          message="This page is still under construction."
          type="info"
          showIcon
        />
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div style={{ height: "750px" }}>
            <Viewer fileUrl={resumeUrl} />
          </div>
        </Worker>
      </div>
    );
  }
}

export default Resume;
