import React from "react";
import "../css/HeaderBar.css";
import HeaderName from "./HeaderName.js";
import LeftNav from "./LeftNav.js";
import { Layout } from "antd";

const { Header, Content } = Layout;

class HeaderBar extends React.Component {
  render() {
    return (
      <Layout>
        <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <HeaderName />
        </Header>
        <Content
          className="site-layout"
          style={{ padding: "0 50px", marginTop: 64 }}
        >
          <LeftNav />
        </Content>
      </Layout>
    );
  }
}

export default HeaderBar;
