import React from "react";
import { Alert } from "antd";

class Project extends React.Component {
  render() {
    return (
      <div className="Project">
        <Alert
          message="This page is still under construction."
          type="info"
          showIcon
        />
        <p>This is Project.</p>
      </div>
    );
  }
}

export default Project;
