import React from "react";
import "../css/HeaderName.css";

class HeaderName extends React.Component {
  render() {
    return (
      <div className="HeaderName">
        <p>Jack (Zhaolin) Zhang</p>
      </div>
    );
  }
}

export default HeaderName;
