import React from "react";
import background from "../res/IMG_3609.jpg";
import { Typography, Space, Row, Col } from "antd";
import { SocialIcon } from "react-social-icons";

const { Title, Paragraph } = Typography;

class HomeIntro extends React.Component {
  render() {
    return (
      <div className="HomeIntro">
        <Row>
          <Col span={24}>
            <Typography>
              <Title>Introduction</Title>
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Typography>
              <Paragraph>
                Hello, I'm Zhaolin Zhang. I've heard, atoms have travelled 14
                billion years through time and space to create us so that we
                could meet. So, thank you for visiting my space.
              </Paragraph>

              <Paragraph>
                I'm a software engineer, that's my job. In daily life, I'm also
                a part-time NGOer, a food lover, and a world traveller.
              </Paragraph>

              <Paragraph>
                A personality test says, I'm a person consists with pragmatism,
                idealism, and platonism. Well, that's me, but not all of me.
                Want to know more? Let's get in touch.
              </Paragraph>

              <Paragraph>
                <Space>
                  <SocialIcon url="https://www.linkedin.com/in/zhaolin-zhang-436b76b7" />
                  <SocialIcon url="https://www.facebook.com/profile.php?id=100007406949583" />
                  <SocialIcon url="https://www.instagram.com/nowyouseeme_jack/" />
                  <SocialIcon url="https://github.com/zhaolinzhang" />
                </Space>
              </Paragraph>
            </Typography>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Typography>
              <Paragraph>
                <img src={background} alt="" width="100%" height="100%" />
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HomeIntro;
